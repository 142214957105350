import Apiservice from '../api.service';
import { API_URL } from '../../../common/config.js'
const apiService = Apiservice;


export default class AcademicCLassTimetablePeriodService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/student/timetable`;

      }
      paginate(timetableId) {
            let url = `${this.#api}/current/period`;
            return apiService.get(url);
      }

      // getClassSubjectsById(timetableId) {
      //       let url = `${this.#api}/${timetableId}/class-subjects`
      //       return apiService.get(url);
      // }


      // update(timetableId,timetablePeriodId, data) {
      //       let url = `${this.#api}/${timetableId}/class-timetable-period/${timetablePeriodId}`
      //       return apiService.put(url, data);
      //
      // }
      //
      // store(timetableId, data) {
      //       let url = `${this.#api}/${timetableId}/class-timetable-period`
      //       return apiService.post(url, data);
      //
      // }
      // checkAvailablity(timetableId, data) {
      //       let url = `${this.#api}/${timetableId}/checkAvailablity`
      //       return apiService.post(url, data);
      //
      // }

      // show(timetableId, timetablePeriodId) {
      //       let url = `${this.#api}/${timetableId}/class-timetable-period/${timetablePeriodId}`
      //       return apiService.get(url);
      // }

      // getByClassAndTeacherId(classId, teacherId) {
      //       let url = `${this.#api}/${classId}/class/${teacherId}/teacher-period`
      //       return apiService.get(url);
      // }
      // delete(timetableId, timetablePeriodId) {
      //       let url = `${this.#api}/${timetableId}/class-timetable-period/${timetablePeriodId}`
      //       return apiService.delete(url);
      // }
}
