<template>
  <div>
    <div v-if="loading">
      <div class="row">
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="cd-schedule cd-schedule--loading margin-top-lg margin-bottom-lg js-cd-schedule" v-if="!loading && academicClassTimetablePeriods && academicClassTimetablePeriods.length>0">
      <div class="cd-schedule__events">
        <ul>
            <li class="cd-schedule__group"  v-for="(schedule,index) in schedule_days" :key='index'>
                <div class="cd-schedule__top-info"><h2>{{schedule.title}}</h2></div>
                <ul v-if="schedule.value.length">
                    <li class="cd-schedule__event" v-for="(day,i) in schedule.value" :key="'sunday'+i" >
                      <span>
                         <a :data-start="day.simple_start_time" :data-end="day.simple_end_time" data-content="event-abs-circuit" :data-event="'event-'+(parseInt(i)+1)" href="#">
                          <em class="cd-schedule__name" v-if="!day.is_break">{{day.subject_name}}</em>
                          <em class="cd-schedule__name" v-else>Break</em>
                          <em class="cd-schedule__name" v-if="!day.is_break">{{day.teacher_name || 'Not Assigned'}}</em>
                                <em class="cd-schedule__name">Class: {{ day.class_name }}</em>
                          </a>
                        </span>
                    </li>
                </ul>
                <ul v-else>
                    <li class="cd-schedule__event no-class-schedule" >
                      <span>
                            <a href="#">
                               <em class="cd-schedule__name" >No Classes</em>
                          </a>
                  </span>
                    </li>
                </ul>
            </li>
          </ul>
        </div>
      </div>
    <div class="row mt-2" v-if="!loading && academicClassTimetablePeriods.length<1">
        <div class="col-md-12 text-center">
            <v-card class="p-20">
                <img src="@/assets/media/images/no-data-found.png">
                <h5 class="mt-10">No timetable available for your class.</h5>
            </v-card>
        </div>
      </div>
  </div>
</template>
<script>
    import UserService from "@/core/services/user/UserService";
    import AcademicCLassTimetableService from "@/core/services/academicClassTimetable/AcademicCLassTimetableService";
    import LevelService from "@/core/services/level/LevelService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import AcademicCLassTimetablePeriodService from "@/core/services/academicClassTimetablePeriod/AcademicCLassTimetablePeriodService";

    const levelService = new LevelService()
    const programService = new ProgramService();
    const academicClassTimetable = new AcademicCLassTimetableService();
    const userService = new UserService();
    const academicCLassTimetablePeriod = new AcademicCLassTimetablePeriodService();
    export default {
        name: "TimeTable",
        data() {

            return {
                time: null,
                currentDay: null,
                start_time: false,
                end_time: false,
                week_days: [
                    {
                        day: 0,
                        title: 'Sunday',
                        value: [],
                    }, {
                        day: 1,
                        title: 'Monday',
                        value: [],
                    },
                    {
                        day: 2,
                        title: 'Tuesday',
                        value: [],
                    }, {
                        day: 3,
                        title: 'Wednesday',
                        value: [],
                    },
                    {
                        day: 4,
                        title: 'Thursday',
                        value: [],
                    }, {
                        day: 5,
                        title: 'Friday',
                        value: [],
                    },
                    {
                        day: 6,
                        title: 'Saturday',
                        value: [],
                    },
                ],
                academicClassTimetable: {
                    class_id: null,
                    title: null,
                    is_working_sunday: null,
                    is_working_monday: null,
                    is_working_tuesday: null,
                    is_working_wednesday: null,
                    is_working_thursday: null,
                    is_working_friday: null,
                    is_working_saturday: null,
                    is_active: 0,
                },
                academicClassTimetablePeriod: {
                    timetable_id: null,
                    day: null,
                    subject_id: null,
                    subject_name: null,
                    teacher_id: null,
                    teacher_name: null,
                    start_time: null,
                    end_time: null,
                    is_break: 0,
                },
                academicClassTimetablePeriods: [],
                loading: true,
                isBusy: true,
                user: null,
                studentSetting: null,
            };
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
            schedule_days() {
                if (this.academicClassTimetablePeriods && this.academicClassTimetablePeriods.length>0) {
                    let formatedDays = this.academicClassTimetablePeriods.map(day => {
                        day.day = parseInt(day.day);
                        return day;
                    })

                    let sunday = formatedDays.filter(obj => {
                        return obj.day == 0;
                    });
                    let monday = formatedDays.filter(obj => {
                        return obj.day == 1;
                    });
                    let tuesday = formatedDays.filter(obj => {
                        return obj.day == 2;
                    });
                    let wednesday = formatedDays.filter(obj => {
                        return obj.day == 3;
                    });
                    let thursday = formatedDays.filter(obj => {
                        return obj.day == 4;
                    });
                    let friday = formatedDays.filter(obj => {
                        return obj.day == 5;
                    });
                    let saturday = formatedDays.filter(obj => {
                        return obj.day == 6;
                    });
                    this.week_days.forEach(element => {
                        switch (element.day) {
                            case 0:
                                this.academicClassTimetable.is_working_sunday ? element.is_working = true : element.is_working = false;
                                element.value = sunday;
                                break;
                            case 1:
                                this.academicClassTimetable.is_working_monday ? element.is_working = true : element.is_working = false;
                                element.value = monday;
                                break;
                            case 2:
                                this.academicClassTimetable.is_working_tuesday ? element.is_working = true : element.is_working = false;
                                element.value = tuesday;
                                break;
                            case 3:
                                this.academicClassTimetable.is_working_wednesday ? element.is_working = true : element.is_working = false;
                                element.value = wednesday;
                                break;
                            case 4:
                                this.academicClassTimetable.is_working_thursday ? element.is_working = true : element.is_working = false;
                                element.value = thursday;
                                break;
                            case 5:
                                this.academicClassTimetable.is_working_friday ? element.is_working = true : element.is_working = false;
                                element.value = friday;
                                break;
                            case 6:
                                this.academicClassTimetable.is_working_saturday ? element.is_working = true : element.is_working = false;
                                element.value = saturday;
                                break;
                            default:
                                return null
                        }

                    })
                    return this.week_days;
                }
                else
                  this.week_days.forEach(element => {
                    switch (element.day) {
                        case 0:
                            this.academicClassTimetable.is_working_sunday ? element.is_working = true : element.is_working = false;
                            break;
                        case 1:
                            this.academicClassTimetable.is_working_monday ? element.is_working = true : element.is_working = false;
                            break;
                        case 2:
                            this.academicClassTimetable.is_working_tuesday ? element.is_working = true : element.is_working = false;
                            break;
                        case 3:
                            this.academicClassTimetable.is_working_wednesday ? element.is_working = true : element.is_working = false;
                            break;
                        case 4:
                            this.academicClassTimetable.is_working_thursday ? element.is_working = true : element.is_working = false;
                            break;
                        case 5:
                            this.academicClassTimetable.is_working_friday ? element.is_working = true : element.is_working = false;
                            break;
                        case 6:
                            this.academicClassTimetable.is_working_saturday ? element.is_working = true : element.is_working = false;
                            break;
                        default:
                            return null
                    }

                })
                return this.week_days;
            },
        },
        mounted() {
            this.getTimeTable();
        },
        methods: {
            getAcademicTimetablePeriod() {
                academicCLassTimetablePeriod.paginate().then((resources) => {
                    this.academicClassTimetablePeriods = resources.data.data;

                }).catch(err => {
                }).finally(()=>{
                    this.loading = false;
                })
            },
            getTimeTable() {
                academicClassTimetable.getTimeTable().then((response) => {
                    this.academicClassTimetable = response.data.academicClassTimetable;
                    if(this.academicClassTimetable)
                      this.getAcademicTimetablePeriod();
                    else
                      this.loading = false;
                }).finally(()=>{

                })
            },
        }
    }
</script>

<style scoped>

</style>
